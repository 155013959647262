import App from './App.vue';
import { createSSRApp } from 'vue'
import router from './router';
import translations from './translations';
import { store } from './store';
import { createI18n } from 'vue-i18n'

import { renderToString } from 'vue/server-renderer'

import gsap from "gsap";
import Utils from "./classes/Utils";
window.Utils=Utils;

window.TweenMax=gsap;      //for Liova code compatibility
window.MyLog=function(msg){
  try {
    let url = window.location.hostname;
    if (url.includes("test.") || url.includes("localhost")) {
      console.log(msg);
    }
  }catch (e) {}
};

const i18n = createI18n({
  locale: 'en',
  messages: translations,
})

const app = createSSRApp(App);
app.use(router)
app.use(store)
app.use(i18n)

const icons = require.context('./assets/svg-icons', false, /.svg/);
icons.keys().forEach((element) => {
  let test = element.replace(/^\.\//, '').replace(/\.\w+$/, '') + '-icon';
  app.component(test, icons(element));
});


app.config.productionTip = false;

app.config.globalProperties.$filters = {
  formatNumber(num) { let addDollarSign = false;
    if(typeof(num) == 'string' && num[0] == '$') {
      addDollarSign = true;
      num = num.slice(1);
    }

    if(num == 0) {
      return 0;
    }
    if(!num) {
      return "";
    }

    num = num.toString().split('.');
    num[0] = Number(num[0]).toLocaleString();
    num = num.join('.');

    if(addDollarSign) {
      num = '$' + num;
    }

    return num;
  }
}

app.mount('#app')

renderToString(app).then((html) => {
})
