module.exports = {
    'WAX': {
        apiUri: "https://wax.greymass.com:443",
        contract: (window.location.hostname.includes("test.") || window.location.hostname.includes("localhost"))? "blockspitest" : "blockspingam",
        chainId: "1064487b3cd1a897ce03ae5b6a865651747e2e152090f99c1d19d44e01aea5a4",
        decimals: 2
    },
    'SOL': {
        //apiUri: "https://rpc.helius.xyz/?api-key=dac8eb32-8210-407d-9d27-d07c63fc609d",
        apiUri: "https://mainnet.helius-rpc.com/?api-key=6f5315b2-73ca-4861-aa34-2e9a08e20c0f",
        contract: (window.location.hostname.includes("test.") || window.location.hostname.includes("localhost"))? "EWFJCAn4X8FPnkxKZhho7HwTVtRPSazWXHFSggfHXV2K" : "2p7iR2vFFuUFcX8GyqgczrSArWrjFWJajxMVfU9oJwtL",
        decimals: 4
    },
    'RON': {
        apiUri: "https://api-gateway.skymavis.com/",
        contract: (window.location.hostname.includes("test.") || window.location.hostname.includes("localhost"))? "0xdd8363f5890a97e5703d6c150faf5eab0df05729" : "0xe8549afc9180475ed3d83ca392c61bdb440f9efa",
        decimals: 4
    },
    'POL': {
        contract: (window.location.hostname.includes("test.") || window.location.hostname.includes("localhost"))? "0x72086fB8c8d3Efa725918B9023a63164E17FaC39" : "0x7E5ca93A7c7162918729519bfDC226F960F88502",
        decimals: 4
    },
    'BNB': {
        contract: (window.location.hostname.includes("test.") || window.location.hostname.includes("localhost"))? "0x72086fB8c8d3Efa725918B9023a63164E17FaC39" : "0x7E5ca93A7c7162918729519bfDC226F960F88502",
        decimals: 8
    },
    'USD': {
        contract: (window.location.hostname.includes("test.") || window.location.hostname.includes("localhost"))? "" : "",
        decimals: 2
    },
    'TON': {
        contract: (window.location.hostname.includes("test.") || window.location.hostname.includes("localhost"))? "UQD-fedGTzkqr0wFLrdM1kEPQIFbaX8uLBe3WcEC4dO6qoBM" : "UQC6LbfuDfyns9EwvXquqE2ZgLQTIyto0i-CIchUGkDJzASN",
        decimals: 4
    },
}
