import {createRouter, createWebHistory } from 'vue-router';

const HomePage = () => import('../pages/home-page/home-page.vue');
const gamePage = () => import('../pages/game-page/game-page.vue');
const scratchPage = () => import('../pages/scratch-page/scratch-page.vue');
const MainWrapper = () => import( '../components/MainWrapper.vue');
const PrivacyPolicy = () => import("../pages/terms and privacy policy/privacy-policy");
const Terms = () => import("../pages/terms and privacy policy/terms");
const AboutUs = () => import("../pages/about us/about-us");
const Jackbot = () => import("../pages/jackbot/jackbot");
const crashPage = () => import("../pages/crash-page/crash-page");
const instantPage = () => import("../pages/instant-page/instant-page");
const spincityPage = () => import("../pages/spincity-page/spincity-page");
const battlePage = () => import("../pages/battle-page/battle-page");
const casinoPage = () => import("../pages/casino-page/casino-page.vue");

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      redirect: '/home',
      component: MainWrapper,
      children: [
        {
          path: '/home',
          name: 'home',
          component: HomePage,
        },
        {
          path: '/terms',
          name: 'terms',
          component: Terms,
        },
        {
          path: '/privacy',
          name: 'privacy',
          component: PrivacyPolicy,
        },
        {
          path: '/about-us',
          name: 'about us',
          component: AboutUs,
        },
        {
          path: '/jackbot',
          name: 'Jackbot',
          component: Jackbot,
        },
        {
          path: '/game/:gameId',
          name: 'game',
          component: gamePage,
        },
        {
          path: '/scratch/:gameId',
          name: 'scratch',
          component: scratchPage,
        },
        {
          path: '/crash/:gameId',
          name: 'crash',
          component: crashPage,
        },
        {
          path: '/instant/:gameId',
          name: 'instant games',
          component: instantPage,
        },
        {
          path: '/spincity/:gameId',
          name: 'Spincity',
          component: spincityPage,
        },
        {
          path: '/slotbattle',
          name: 'slotbattle',
          component: battlePage,
        },
        {
          path: '/casino/:gameId',
          name: 'casino',
          component: casinoPage,
        },
      ]
    },
  ],
});

export default router;
