export default new class Utils {
    constructor() {
        this.uploadSize=2621440;
    }
    isNumber(value) {
        let ret=false;
        try{
            ret=!isNaN(value) && !isNaN(parseFloat(value));
        }
        catch (e) {}
        return ret;
    }
    getDateOrNow(timestamp) {
        let date;
        if (!timestamp)
            date = new Date.now();
        else
            date = new Date(timestamp);
        const months = ["Jan.", "Feb.", "Mar.", "Apr.", "May.", "Jun.", "Jul.", "Aug.", "Sep.", "Oct.", "Nov.", "Dec."];
        return months[date.getMonth()] + " " + (date.getDate()) + ", " + date.getFullYear();
    }
}
