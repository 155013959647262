export default {
    m020:"Level",
    m037_02:"CHIPS",
    m050_03:"Prize",
    m030_15_3:"Quests",
    m030_15_4:"QUESTS",
    m030_15_5:"Quest completed",
    m055_01:"Quests",
    m055_02:"Tasks",
    m055_03:"Daily",
    m055_04:"Social",
    m055_05:"Link",
    m055_06:"Upload\nscreenshot",
    m055_07:"Select\nscreenshot",
    m055_08:"Maximum image size 2.5MB",
    reward_prize_type:{
        chips:'CHIPS',
        gems:'GEMS',
    },
}
