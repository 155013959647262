<template>
  <div id="app" :class="['l-main', { 'l-main--home': isHomeScreen }]">
    <cookie-popup />
    <router-view />
  </div>
</template>

<script>
import CookiePopup from "./components/CookiePopup";

export default {
  name: "App",
  components: {
    CookiePopup,
  },
  data() {
    return {
      isHomeScreen: false,
    };
  },
  watch: {
    $route(to, from) {
      this.isHomeScreen = to.name === "home";
    },
  },
  created() {
  },
  methods:{

  },
};
</script>

<style lang="scss">
  @import "./assets/styles/main";
  @import "./assets/styles/transitions";
  @import url('https://fonts.googleapis.com/css?family=Rubik:300,300i,400,400i,500,500i,700,700i,900,900i');

  .l-main--home {
    background: $white;
  }

  .c-components_body {
    /*Body style for components in Zero level on device screen*/
    position: fixed;
    display: flex;
    overflow: hidden;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 0;
    margin: 0;
    width: 100vw;
    height: 100vh;
    transform: translate(0%, 0%);
    top: 0;
    left: 0;
  }

  .c-components_abs {
    /*Second style into body for components in Zero level on device screen*/
    display: flex;
    overflow: hidden;
    position: absolute;
    transform: translate(0%, 0%);
    padding: 0;
    margin: 0;

    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .c-app_css {
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    overflow: hidden;
  }

  .c-app_ccs {
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    overflow: hidden;
  }

  .c-app_ccc {
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }

  .c-app_csc {
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;
  }

  .c-app_rss {
    display: flex;
    position: relative;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    overflow: hidden;
  }

  .c-app_rcs {
    display: flex;
    position: relative;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    overflow: hidden;
  }

  .c-app_rcc {
    display: flex;
    position: relative;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }

  .c-app_rsc {
    display: flex;
    position: relative;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;
  }

  .c-app_rbc {
    display: flex;
    position: relative;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
  }

  /*   scroll dinamic   */
  .c-app_scroll {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;
    overflow-y: visible;
  }


</style>
